<template>
	<div :class="[{ 'wrapper_main':!innerTab }, { 'min-h-300':innerTab }, { 'processing':loading }]">
		<main :class="{ 'content':!innerTab }" v-if="!loading">
			<div class="content__header" v-if="!innerTab">
				<div class="content__header-left">
					<h1 class="heading-page-h1">{{ $translate('actions-list') }}</h1>
				</div>
				<div class="content__header-right">
					<BluePlusButton to="add-action">{{ $translate('add-action') }}</BluePlusButton>
				</div>
			</div>

			<TableAdvanced v-model="sortCol" :rows="filteredRows" :columns="columns" :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs" :class="{'table_on_print':print}">
				<template v-slot:filters v-if="!print">
					<div class="filter-options__search">
                        <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="search" @input="initTables" />
                    </div>
					<div class="filter-options__items flex-x-wrap flex-auto">
                        <!-- <DatePicker v-model="filterStartDate" dateFormat="MM.dd.yyyy" :canClear="true" />
                        <DatePicker v-model="filterEndDate" dateFormat="MM.dd.yyyy" :canClear="true" />
                        <div class="categories time-piker dropdown-wrapper" v-if="!fieldBookList">
                            <TimePicker v-model="filterTime" initValue="00:00" :canClear="true" />
                        </div> -->
					</div>
					<div class="flex items-center">
						<span class="select-title">{{ $translate('sort-by') }}</span>
						<Dropdown
							:options="fieldBookList ? ['Date', 'Name of the Operation', 'Category', 'Size of the field'] : ['Date', 'Category', 'Subcategory', 'Size of the field']"
							:current="sortField"
							name="sortFeild"
							@change="updateSortField"
                            class="open_to_right"
						/>
						<button type="button" class="btn btn-sm btn-w-shadow size-40-40" data-target="download" @click="openDownloadList" v-if="!fieldBookList">
							<inline-svg :src="require(`@/assets/img/ico-download.svg`)"/>
						</button>
					</div>
				</template>

				<template v-slot:row="slotData">
                    <template v-if="!fieldBookList">
                        <td>
                            <label class="module__check">
                                <!-- <input type="checkbox" name="category" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event, slotData.r.id)"/>
                                <span class="check"></span> -->
                                <span class="text" @click.self.stop="viewAction(slotData.r.id)" v-if="innerTab">
                                    {{ slotData.r.name_of_the_operation || '-' }}
                                </span>
                                <span class="text" v-else @click.self.stop="viewAction(slotData.r.id)">
                                    {{ slotData.r.name_of_the_operation || '-' }}
                                </span>
                            </label>
                        </td>
                        <td style="text-transform: capitalize;">
                            {{ slotData.r.type_of_acivity ? formatTypeOfActivity(slotData.r.type_of_acivity) : '-' }}
                        </td>
                        <td>{{ slotData.r.date_of_the_operation }}</td>
                        <!--  m<sup>3</sup> -->
                        <td>{{ slotData.r.product_category || '-' }}</td>
                        <td>
                            {{ slotData.r.related_persons[0] ? clearEmailFromPerson(slotData.r.related_persons[0]) : '-' }}
                            <!-- {{ slotData.r.name_of_the_person || '-' }} -->
                        </td>
                    </template>
                    <template v-else>
                        <td>
                            <label class="module__check" v-if="!print">
                                <!-- <input type="checkbox" name="category" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event, slotData.r.id)"/>
                                <span class="check"></span> -->
                                <span class="text" @click.self.stop="viewAction(slotData.r.id)" v-if="innerTab">
                                    {{ slotData.r.date_of_the_operation }}
                                </span>
                                <span class="text" v-else>{{ slotData.r.date_of_the_operation || '-' }}</span>
                            </label>
                            <span class="text" v-else>{{ slotData.r.date_of_the_operation || '-' }}</span>
                        </td>
                        <td>
                            <!-- <a @click.self.stop="$emit('singleActionChange', slotData.r.id)">
                                {{ slotData.r.name_of_the_operation || '-' }}
                            </a> -->
                            <router-link :to="`/action/view/${slotData.r.id}`" class="no-color">
                                {{ slotData.r.name_of_the_operation || '-' }}
                            </router-link>
                        </td>
                        <td>{{ slotData.r.product_category || '-' }}</td>
                        <!-- <td>{{ slotData.r.weight || '-' }}</td> -->
                        <!-- <td>{{ slotData.r.field_size || '-' }}</td> -->
                        <td style="text-transform: capitalize;">
                            {{ formatTypeOfActivity(slotData.r.type_of_acivity) || '-' }}
                        </td>
                        <!-- <td>{{ varietyFields[slotData.r.id] ? varietyFields[slotData.r.id] : '-' }}</td> -->
                        <td>{{ slotData.r.adversity || '-' }}</td>
                        <td>{{ slotData.r.name_of_the_person || '-' }}</td>
                    </template>
                    <td class="position-stiky">
                        <div class="table-options-drop dropdown-wrapper">
                            <button
                                type="button" class="btn btn-sm btn-transp dropdown-btn" 
                                :class="{'active': menuOpen.open === slotData.r.id }"
                                @click="openOptions($event, slotData.r.id)"
                            >
                                <inline-svg :src="require('@/assets/img/dots-menu.svg')" class="pointer-events-none" />
                            </button>
                            <div 
                                class="categories__dropdown dropdown-content" 
                                :class="{'active': menuOpen.open === slotData.r.id }"
                                style="position: fixed;"
                                :style="{ left: menuOpen.left, top: menuOpen.top, bottom: 'auto' }"
                            >
                                <ul>
                                    <li>
                                        <a @click="edit(slotData.r)">{{ $translate('edit') }}</a>
                                    </li>
                                    <li>
                                        <a>{{ $translate('report') }}</a>
                                    </li>
                                    <li class="list-line"></li>
                                    <li>
                                        <button type="button" class="red-color" @click="openConfirmDelete(slotData.r)">
                                            {{ $translate('delete') }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
				</template>	
			</TableAdvanced>
			<div class="empty_table_data_info" v-if="!sourceData.length">
                <p>{{ $translate('actions-not-found-reset-filters-or-add-a-new-action') }}</p>
            </div>
			<Pagination v-model="filteredRows" :items="rawSource" v-if="!print" />
		</main>

		<FarmListDownload
            v-if="downloadList"
            tableName="Actions List"
            @close="closeDownloadList"
            @confirm="confirmDownloadList"
        />

		<ConfirmAction
			v-if="deletePopupInfo.show" 
			title="Delete Action?" 
			:message="'Do you confirm delete a action '+ deletePopupInfo.deleteId + '?'" 
			@close="confirmDeleteClose" 
			@confirm="deleteAction(deletePopupInfo.deleteId)" 
			:processing="loading"
		/>
	</div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";
import { API } from 'aws-amplify';
import { mixTables,mixDeleteItem } from '@/mixins';
import { mixTabs } from '@/mixins';
import { deleteFarmAction, } from '@/graphql/mutations';
import actionBasicInfoConfig from "@/config/farm";

export default {
    name: "ActionsList",
    components: {
		...Base,
        ...Popups,
		...Buttons
    },
    props: ['innerTab', 'subcategoryFilter', 'fieldBookList', 'print' ],
	mixins:[mixTabs, mixTables, mixDeleteItem],
    data() {
        return {
            search: '',
            sortField: "Date",
            filterStartDate: null,
            filterEndDate: null,
			filterTime: '00:00',
            downloadList: false,
			loading: true,
            source: 'farmActions',
			columns: [],
            menuOpen: {
                left: '-500px',
                top: 0,
                open: null,
            } ,
			deletePopupInfo: {
                show: false,
                deleteId: null,
            },
			farmActionsLocal: [],
        }
    },
    computed: {
		farmActions(){
			return this.$store.state.farmActions;
		},
		sourceData(){
            return this.farmActionsLocal.filter(item => item?.category?.toLowerCase().includes(this.search.toLowerCase()) 
				|| item?.subcategory?.toLowerCase().includes(this.search.toLowerCase()) 
				|| item?.name_of_the_operation?.toLowerCase().includes(this.search.toLowerCase())
				|| item?.createdAt?.toLowerCase().includes(this.search.toLowerCase())
            );
        },
        paddockList(){
            return this.$store.state.paddocks;
        },
        // varietyFields(){
        //     let returnedObject = {};
        //     this.sourceData.forEach(actionItem => {
        //         if(actionItem.related.length){
        //             actionItem.related.forEach(itemId => {
        //                 let findedItem = this.paddockList.find(item => item.id === itemId && item.type === "field" && item.basic_info.plots_config);
        //                 if(findedItem){
        //                     let allVarietyData = [];
        //                     findedItem.basic_info.plots_config.forEach(plotsItem => {
        //                         if(plotsItem.variety.length){
        //                             for (let index = 0; index < plotsItem.variety.length; index++) {
        //                                 allVarietyData.push(plotsItem.variety[index]);
        //                             }
        //                         }
        //                     })

        //                     if(allVarietyData.length){
        //                         returnedObject[actionItem.id] = allVarietyData.join(', ');
        //                     } else {
        //                         returnedObject[actionItem.id] = null;
        //                     }  
        //                 } else {
        //                     returnedObject[actionItem.id] = null;
        //                 }
        //             })
        //         } else {
        //             returnedObject[actionItem.id] = null;
        //         }
        //     })
            
        //     return returnedObject;
        // },
        typeOfActivityTitles(){
            return Object.entries(actionBasicInfoConfig.typeOfActivity).map(item => item[0])
        }
	},
	watch: {
        filterStartDate(){
            this.filtersDateChange();
        },
        filterEndDate(){
            this.filtersDateChange();
        },
        filterTime(){
            this.filtersDateChange();
        },
    },
    methods: {
        formatTypeOfActivity(typeValue){
            let typeTitle = null;

            this.typeOfActivityTitles.forEach(element => {
                if(!typeTitle && typeValue.includes(element)){
                    typeTitle = element;
                }
            });

            return typeTitle ? typeValue.slice(typeTitle.length).split('_').filter(item => item).join(" ") : typeValue;
        },
        clearEmailFromPerson(person){
            return person.slice(0, person.indexOf(' ('));
        },
       	updateSortField(ev, forceupdate = false) {
            let target = null;
            let value = null;
            if(!forceupdate){
                target = ev.target;
                value = target.value; 
            } else {
                target = null;
                value = ev;
            }

            this.sortField = value;

            this.farmActionsLocal.sort(function(a, b){
                if(value === 'Category'){
                    if(a.category.toLowerCase() < b.category.toLowerCase()) { return -1; }
                    if(a.category.toLowerCase() > b.category.toLowerCase()) { return 1; }
                }

                if(value === 'Subcategory'){
                    if(a.subcategory.toLowerCase() < b.subcategory.toLowerCase()) { return -1; }
                    if(a.subcategory.toLowerCase() > b.subcategory.toLowerCase()) { return 1; }
                }

                if(value === 'Size of the field'){
                    if(a.field_size > b.field_size) { return -1; }
                    if(a.field_size < b.field_size) { return 1; }
                }

                if(value === 'Name of the Operation'){
                    if(a.name_of_the_operation > b.name_of_the_operation) { return -1; }
                    if(a.name_of_the_operation < b.name_of_the_operation) { return 1; }
                }

                if(value === 'Date'){
                    if(a.createdAt < b.createdAt) { return -1; }
                    if(a.createdAt > b.createdAt) { return 1; }
                }

                return 0;
            });
            this.initTables();
        },
		filtersDateChange(){
            if(this.filterStartDate && this.filterEndDate){
                this.farmActionsLocal = [...this.farmActions].filter(item => new Date(item.createdAt) >= new Date(`${this.filterStartDate}, ${this.filterTime}`) 
                    && new Date(item.createdAt) <= new Date(`${this.filterEndDate}, ${this.filterTime}`));
            }

            if(this.filterStartDate && !this.filterEndDate) {
                this.farmActionsLocal = [...this.farmActions].filter(item => new Date(item.createdAt) >= new Date(`${this.filterStartDate}, ${this.filterTime}`));
            }

            if(this.filterEndDate && !this.filterStartDate) {
                this.farmActionsLocal = [...this.farmActions].filter(item => new Date(item.createdAt) <= new Date(`${this.filterEndDate}, ${this.filterTime}`));
            }

            if(!this.filterStartDate && !this.filterEndDate) {
                this.farmActionsLocal = [...this.farmActions];
            }

            this.updateSortField(this.sortField, true);
            this.initTables();
        },
        openOptions(ev, id) {
            if(this.menuOpen.open === id){
                this.menuOpen.open = null;
                this.menuOpen = {
                    left: '-500px',
                    top: 0,
                    open: null,
                }
            } else {
                this.menuOpen.open = id;
                setTimeout(() => {
                    let targetClientRect = ev.target.getBoundingClientRect();

                    this.menuOpen.left = (targetClientRect.x - ev.target.nextElementSibling.offsetWidth + 22) + 'px';

                    if((targetClientRect.top + targetClientRect.height + ev.target.nextElementSibling.offsetHeight+70) > document.querySelector('#app').offsetHeight){
                        this.menuOpen.top = ((targetClientRect.y - ev.target.nextElementSibling.offsetHeight) - 10) + 'px';
                    } else {
                        this.menuOpen.top = (targetClientRect.y + 15) + 'px';
                    }
                }, 10);
            }
        },
        outsideMenuClick(e){
            if(this.menuOpen.open&&!e.target.closest('.table-options-drop.dropdown-wrapper')){
                this.menuOpen.open = null;
            }
        },
        pushRoute(page) {
            this.$router.push({ path: page });
        },
        openDownloadList() {
            this.downloadList = true;
        },
        closeDownloadList() {
            this.downloadList = false;
        },
        confirmDownloadList(format) {
            console.log(format);
            this.closeDownloadList();
        },
		openConfirmDelete(action){
            this.deletePopupInfo.deleteId = action.id;
            setTimeout(() => {
                this.deletePopupInfo.show = true;
            }, 50);
            this.menuOpen.open = null;
        },
        confirmDeleteClose(){
            this.deletePopupInfo = {
                show: false,
                deleteId: null,
            };
        },
		async deleteAction(deleteId){
            this.loading = true;
			await API.graphql({
                query: deleteFarmAction,
                variables: {
                    input: {
                        id: deleteId,
                    }
                }
            });

            this.confirmDeleteClose();
            await this.$store.dispatch('GET_FARMACTIONS');
            if(this.subcategoryFilter){
                this.farmActionsLocal = [...this.farmActions].filter(item => item.subcategory === this.subcategoryFilter);
            } else {
                this.farmActionsLocal = [...this.farmActions];
            }

            this.initTables();
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },
		edit(action){
            this.$router.push({ path: `/action/${action.id}`, query: { 'tab': 'info' } });
			this.menuOpen.open = null;
        },
        viewAction(actionId){
            console.log('viewAction:', actionId);
            this.$emit('singleActionChange', actionId);
            this.$router.push({ path: `/action/view/${actionId}`, query: { 'tab': 'info' } });
        },
    },
	async created(){
        console.log('typeOfActivityTitles:', this.typeOfActivityTitles);
        if(!this.fieldBookList){
            this.columns = [
                // Name
                // Type
                // Date
                // Product
                // User
				{ name: 'Name', filter: 'name_of_the_operation' },
                { name: 'Type', filter: 'type_of_acivity' },
				{ name: 'Date', filter: 'createdAt' },
				{ name: 'Product', filter: 'product_category' },
				{ name: 'User', filter: 'related_persons' },

				// { name: 'Category', filter: 'category' },
				// { name: 'Subcategory', filter: 'subcategory' },
				// { name: 'Size of the field', filter: 'field_size', },
				// { name: 'Date of the operation', filter: 'createdAt', colspan: 2, class: 'w-100' },
			];
        } else {
            this.columns = [     
                { name: 'Date', filter: 'date_of_the_operation' },
                { name: 'Name of the operation', filter: 'name_of_the_operation' },
                { name: 'Product', filter: 'product_category' }, //product_category
                // { name: 'Weight ', filter: 'weight' },
                // { name: 'Size', filter: 'field_size' },
                { name: 'Activity type', filter: 'type_of_acivity' },
                // { name: 'Variety', filter: '' },
                { name: 'Adversity', filter: 'adversity' },
                // class: 'w-100'
                { name: 'Name', filter: 'name_of_the_person', colspan: 2, },
            ];
        };

        let farm = null;
        farm = await this.$store.dispatch('GET_FARM');
        if(farm){
            await this.$store.dispatch('GET_PADDOCKS');
        }

		await this.$store.dispatch('GET_FARMACTIONS');
        if(this.subcategoryFilter){
            this.farmActionsLocal = [...this.farmActions].filter(item => item.subcategory === this.subcategoryFilter);
        } else {
            this.farmActionsLocal = [...this.farmActions];
        }

        this.$emit('initedActions', this.farmActionsLocal);

        this.farmActionsLocal.forEach(element => {
            if(!element.name_of_the_operation){
                element.name_of_the_operation = '-';
            }

            element.related.forEach(relatedItemId => {
                let findedPaddock = this.paddockList.find(paddockItem => paddockItem.id === relatedItemId);

                if(findedPaddock && findedPaddock.basic_info.total_area){
                    element.field_size = findedPaddock.basic_info.total_area;
                } else {
                    element.field_size = 0;
                }
            })

            if(!element.related.length) {
                element.field_size = 0;
            }
        });

		this.initTables();
		this.loading = false;

        let self = this;
        window.addEventListener('click', self.outsideMenuClick);
	},
    beforeDestroy(){
        let self = this;
        window.removeEventListener('click', self.outsideMenuClick);
    }
};
</script>

<style>
.no-color {
    color: inherit;
}
</style>